<link rel="https://cdn.rawgit.com/mfd/f3d96ec7f0e8f034cc22ea73b3797b59/raw/856f1dbb8d807aabceb80b6d4f94b464df461b3e/gotham.css">
<div id="page" style="position: relative;">
  <div id="stripe">

  </div>
  <div class="container-fluid">
    <div class="row align-items-center" id="logorow">
      <div class="col-3">
        <img id="logo" src="../assets/logo_navigator.png">
      </div>
      <div class="col">
        <a href="https://fr.navigator.raizescitadinas.com" style="float: right;margin-right: 7%;color: white;" *ngIf="!french">FR</a>
        <a href="https://navigator.raizescitadinas.com" style="float: right;margin-right: 7%;color: white;" *ngIf="french">NL</a>
      </div>
    </div>
  </div>
  <div id="videodiv">
    <img *ngIf="!french" loop=infinite width="100%" src="../assets/video_fundo_horizontal_holandes.gif" id="iphonestatico">
    <img *ngIf="!french" loop=infinite width="100%" src="../assets/video_fundo_vertical_mobile_holandes.gif" id="iphonemobile">
    <img *ngIf="!french" loop=infinite width="100%" src="../assets/video_fundo_vertical_holandes.gif" id="iphonetablet">
    <!--FR-->
    <img *ngIf="french" loop=infinite width="100%" src="../assets/Imagens_Frances/video_fundo_horizontal_frances.gif" id="iphonestatico">
    <img *ngIf="french" loop=infinite width="100%" src="../assets/Imagens_Frances/video_fundo_vertical_mobile_frances.gif" id="iphonemobile">
    <img *ngIf="french" loop=infinite width="100%" src="../assets/Imagens_Frances/video_fundo_vertical_frances.gif" id="iphonetablet">
  </div>
  <div id="stripe2"></div>
  <div class="imgtxt">
    <div id="imgresma">
    <img *ngIf="!french" id="resma" src="../assets/Imagens_Holandes/resma.png">
    <img *ngIf="french" id="resma" src="../assets/Imagens_Frances/resma.png">
    </div>
    <div id="txtresma">
      <div id="txtinner">
      <p *ngIf="!french" id="text">ELKE RIEM VAN NAVIGATOR KANTOORPAPIER BIEDT U EEN KANS OM EEN IPHONE X TE WINNEN.</p>
      <p *ngIf="french" id="text">CHAQUE PAQUET DE NAVIGATOR OFFICE PAPER VOUS DONNE UNE CHANCE DE GAGNER UN IPHONE X</p>
      </div>
    </div>
  </div>
  <div class="imgtxt">
    <div id="txtresma">
      <div id="txtinner2">
      <p *ngIf="!french" id="text2">GEBRUIK DE CODE OP DE ACHTERZIJDE VAN ELKE NAVIGATOR-RIEM OM DEEL TE NEMEN.</p>
      <p *ngIf="french" id="text2">POUR PARTICIPER, UTILISEZ LE CODE QUI SE TROUVE À L'ARRIÈRE DE CHAQUE PAQUET DE NAVIGATOR OFFICE PAPER.</p>
      </div>
    </div>
    <div id="imgiphone">
    <img *ngIf="!french" id="iphone" src="../assets/Imagens_Holandes/iphone.png">
    <img *ngIf="french" id="iphone" src="../assets/Imagens_Frances/iphone.png">
    </div>
    
  </div>
  <div class="wtg">
    <h2 *ngIf="!french" id="wt" >WAAR NAVIGATOR  <BR>PAPIER KOPEN:</h2>
    <h2 *ngIf="french" id="wt" >OÙ OBTENIR  <BR>LE NAVIGATOR PAPER:</h2>
  </div>
  <div id="butcont">
    <div id="b1box" class="imgbox" >
      <div id="b1b" class="imgbox">
      <a href="https://www.calipage.nl"><img *ngIf="!french" class="bs"  src="../assets/botao_1_metade_1.png"></a>
      <a href="https://www.calipage.nl"><img *ngIf="french" class="bs"  src="../assets/Imagens_Frances/bot11.png"></a>
      <a href="https:/www.calipage.be "><img *ngIf="!french" class = "bs" src="../assets/botao_1_metade_2.png"></a>
      <a href="https:www.calipage.be "><img *ngIf="french" class = "bs" src="../assets/Imagens_Frances/bot12.png"></a>
    </div>
    </div>
    <div style="width: 1%;">

    </div>
    <div id="b1box" class="imgbox" >
      <a href="https://www.officedealonline.nl"><img *ngIf="!french" class="bs"  src="../assets/botao_2.png"></a>
      <a href="https://www.officedealonline.nl"><img *ngIf="french" class="bs"  src="../assets/Imagens_Frances/bot2.png"></a>
    </div>
  </div>
  <div id="stripe3"></div>
  <div id="win">
    <h1 *ngIf=!french id="winan" style="color: black;">WIN EEN IPHONE X</h1>
    <h1 *ngIf=french id="winan" style="color: black;">GAGNEZ UN IPHONE X</h1>
  </div>
  <div>
    <form [formGroup]="form" autocomplete="off"  *ngIf="!french" id="form"  (ngSubmit)="submeter()" >
      <input autocomplete="no" id="nome"  type="text" name="nome" placeholder="Naam" formControlName="nome" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.nome.errors}">
      <input style="width: 96%;" id="email" type="text" name="email" placeholder="E-mail" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors}">
      <div class="formitem">
      <input id="loja" type="text" name="loja" placeholder="Winkel" formControlName="loja" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.loja.errors}">
      <input id="data" type="text" onfocus="(this.type='date')" name="data" formControlName="data" placeholder="Datum van aankoop" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.data.errors}">
      </div>
      <div class="formitem">
      <input id="loja" type="text" name="ReamCode" placeholder="Code" formControlName="ReamCode" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.ReamCode.errors}">
      <input id="data" type="text" name="pais" placeholder="Land" formControlName="pais" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.pais.errors}">
    </div>
    <div *ngIf="repetido && !french" style="color: red;margin-top: 15px;" id="repetido">reeds geregistreerde code</div>
    <div *ngIf="repetido && french" style="color: red;margin-top: 15px;" id="repetido">Code déjà soumis</div>
    <div *ngIf="notchecked && !french" style="color: red;margin-top: 15px;" id="repetido">u moet de voorwaarden accepteren</div>
    <div *ngIf="notchecked && french" style="color: red;margin-top: 15px;" id="repetido"> vous devez accepter les termes et condition</div>
      <button style=" border-width: 2px;margin-top:35px" type="submit" value="submit" name="submit">INZENDEN </button>
      </form>
      <!--FR-->
      <form [formGroup]="form" autocomplete="off"  *ngIf="french" id="form"  (ngSubmit)="submeter()" >
        <input autocomplete="no" id="nome"  type="text" name="nome" placeholder="Nom" formControlName="nome" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.nome.errors}">
        <input style="width: 96%;" id="email" type="text" name="email" placeholder="Courrier électronique " formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors}">
        <div class="formitem">
        <input id="loja" type="text" name="loja" placeholder="Magasin" formControlName="loja" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.loja.errors}">
        <input id="data" type="text" onfocus="(this.type='date')" name="data" formControlName="data" placeholder="Date d'achat" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.data.errors}">
        </div>
        <div class="formitem">
        <input id="loja" type="text" name="ReamCode" placeholder="Code" formControlName="ReamCode" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.ReamCode.errors}">
        <input id="data" type="text" name="pais" placeholder="Pays" formControlName="pais" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.pais.errors}">
        <div *ngIf="repetido" style="color: red;" id="repetido">Código já usado</div>
        <div *ngIf="notchecked" style="color: red;" id="repetido">Tem de aceitar os termos e condições</div>
      </div>
        <button style=" border-width: 2px;margin-top:35px" type="submit" value="submit" name="submit">ENVOYER </button>
        </form>
      <div>
        <div id="terms">
      <input type="checkbox" id="check" name="check" value="accept" >
      <label *ngIf="!french" style="margin-left: 5px;" for="vehicle1"> Ik heb de <a href="./assets/termsNL.html">voorwaarden</a> gelezen, begrepen en geaccepteerd.</label>
      <label *ngIf="french" style="margin-left: 5px;" for="vehicle1"> J'ai lu, compris et accepté les <a href="./assets/termsFR.html">termes et conditions.</a></label>
      </div>
    </div>
  </div>
  <div *ngIf="!submit" style="margin-bottom: 350px;"></div>
  <div *ngIf="submit">
  <div id="tybox">
    <h1 *ngIf="!french" id="ty">BEDANKT VOOR UW <br> DEELNAME</h1>
    <h1 *ngIf="french" id="ty">MERCI POUR VOTRE   <br> PARTICIPATION.</h1>
    <h3 *ngIf="!french" id="ns">Nu delen met uw vrienden</h3>
    <h3 *ngIf="french" id="ns">Partagez maintenant avec vos amis</h3>
    <p class="hashtag">#turnyourpaperintoaniphonex</p>
    <p style="margin-bottom: 20px;" class="hashtag">#paperintoiphonex</p>
    <div>
      <div id="share">
        <a *ngIf="!french"  href="https://www.facebook.com/sharer/sharer.php?u=http://navigatoradveo.com"><img class="partilha" style="margin-right: 10px;" src="../assets/Imagens_Holandes/partilha_face.png"></a>
        <a *ngIf="!french"  href="https://www.linkedin.com/shareArticle?mini=true&url=http://navigatoradveo.com&title=&summary=&source="><img class="partilha" style="margin-right: 10px;"  src="../assets/Imagens_Holandes/partilha_insta.png"></a>
        <a *ngIf="!french"  href="https://twitter.com/home?status=http://navigatoradveo.com "><img class="partilha" style="margin-right: 10px;"  src="../assets/Imagens_Holandes/partilha_twitter.png"></a>
        <a *ngIf="french"  href="https://www.facebook.com/sharer/sharer.php?u=http://fr.navigatoradveo.com"><img class="partilha" style="margin-right: 10px;" src="../assets/Imagens_Holandes/partilha_face.png"></a>
        <a *ngIf="french"  href="https://www.linkedin.com/shareArticle?mini=true&url=http://fr.navigatoradveo.com&title=&summary=&source="><img class="partilha" style="margin-right: 10px;"  src="../assets/Imagens_Holandes/partilha_insta.png"></a>
        <a *ngIf="french"  href="https://twitter.com/home?status=http://fr.navigatoradveo.com "><img class="partilha" style="margin-right: 10px;"  src="../assets/Imagens_Holandes/partilha_twitter.png"></a>
      </div>
    </div>
  </div>
  <div id="stripe4"></div>
  <div id="footer">
    <div>
      <div style="margin-bottom: 20px;" id="redesociais">
        <a href="https://www.facebook.com/navigatorpaper/"><img  href="https://www.facebook.com/navigatorpaper/"class="redesociais" style="margin-right:5px" src="../assets/Imagens_Holandes/redes_sociais_face.png"></a>
        <a href="https://www.instagram.com/navigatorpaper/?hl=pt"><img class="redesociais" style="margin-right:5px" src="../assets/Imagens_Holandes/redes_sociais_insta.png"></a>
        <a href="https://twitter.com/NavigatorSocial"><img class="redesociais" style="margin-right:5px" src="../assets/Imagens_Holandes/redes_sociais_twitter.png"></a>
        <a href="https://www.youtube.com/thenavigatorcompanychannel"><img class="redesociais" style="margin-right:5px" src="../assets/Imagens_Holandes/redes_sociais_youtube.png"></a>
      </div>
    </div>
    <div>
      <div  id="logos">
        <img class="logo" style="margin-right: 20px;" src="../assets/footer_logo_1.png">
        <img class="logo" style="margin-right: 20px;" src="../assets/footer_logo_2.png">
        <img class="logo" style="margin-right: 20px;" src="../assets/footer_logo_3.png">
        <img class="logo" style="margin-right: 20px;" src="../assets/footer_logo_4.png">
        <img id="logo5" class="logo" style="margin-right: 20px;" src="../assets/footer_logo_5.png">
        <img id="logo6" class="logo" style="margin-right: 20px;" src="../assets/footer_logo_6.png">
      </div>
    </div>
    <div style="margin-top: 50px;margin-bottom: 50px;">
      <div id="navlog">
        <img id="smallogo" src="../assets/footer_logo_navigator.png">
        <p id="copy" style="margin-left: 10px;font-size: small;">Copyright 2020 All Rights Reserved Cookies | Privacy</p>
      </div>
    </div>
  </div>

</div>
<div *ngIf="!submit" id="footer2">
  <div>
    <div style="margin-bottom: 20px;" id="redesociais">
      <a href="https://www.facebook.com/navigatorpaper/"><img class="redesociais" style="margin-right:5px" src="../assets/Imagens_Holandes/redes_sociais_face.png"></a>
      <a href="https://www.instagram.com/navigatorpaper/?hl=pt"><img class="redesociais" style="margin-right:5px" src="../assets/Imagens_Holandes/redes_sociais_insta.png"></a>
      <a href="https://twitter.com/NavigatorSocial"><img class="redesociais" style="margin-right:5px" src="../assets/Imagens_Holandes/redes_sociais_twitter.png"></a>
      <a href="https://www.youtube.com/thenavigatorcompanychannel"><img class="redesociais" style="margin-right:5px" src="../assets/Imagens_Holandes/redes_sociais_youtube.png"></a>
    </div>
  </div>
  <div>
    <div  id="logos">
      <img class="logo" style="margin-right: 20px;" src="../assets/footer_logo_1.png">
      <img class="logo" style="margin-right: 20px;" src="../assets/footer_logo_2.png">
      <img class="logo" style="margin-right: 20px;" src="../assets/footer_logo_3.png">
      <img class="logo" style="margin-right: 20px;" src="../assets/footer_logo_4.png">
      <img id="logo5" class="logo" style="margin-right: 20px;" src="../assets/footer_logo_5.png">
      <img id="logo6" class="logo" style="margin-right: 20px;" src="../assets/footer_logo_6.png">
    </div>
  </div>
  <div style="margin-top: 50px;margin-bottom: 50px;">
    <div id="navlog">
      <img id="smallogo" src="../assets/footer_logo_navigator.png">
      <p id="copy" style="margin-left: 10px;font-size: small;">Copyright 2020 All Rights Reserved Cookies | Privacy</p>
    </div>
  </div>
</div>

</div>
<iframe name="frame"></iframe>